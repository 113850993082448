// translations.js

// import beforeImageSofa from '../assets/kanapa_przed.jpeg';
// import afterImageSofa from '../assets/kanapa_po.jpeg';
// import beforeImageSara from '../assets/sara_przed.jpeg';
// import afterImageSara from '../assets/sara_po.jpeg';

export const translations = {
    en: {
      home: 'Home',
      serviceAreaTextLine1: "We operate in the",
    serviceAreaTextLine2: "Tricity area and surroundings",  // Nowy klucz dla angielskiego
    introText: `Welcome to our cleaning company, where cleanliness meets professionalism. We specialize in comprehensive cleaning services tailored to your needs. Our team of experienced professionals will ensure that your home, apartment, upholstered furniture, and even your car interior shine with cleanliness.`,
    ourOfferIncludes: 'Our offer includes:',
    services: {
      homeCleaning: {
        title: 'House and Apartment Cleaning',
        description: `
        <p><strong>Our house and apartment cleaning service is a guarantee of pristine cleanliness, comfort and satisfaction with your surroundings.</strong> Our professional staff will take care of every detail, even the smallest, tailoring the scope of work to your individual needs and preferences. We understand how valuable your time is, so we offer flexible deadlines and comprehensive solutions to make your daily life easier. Let us relieve you of your daily chores so you can enjoy your free time in a clean, cozy and healthy environment. <strong>Contact us today </strong>and see how we can make your life easier!</p>
      
      <h3>What do we offer?</h3>
      <ul>
        <li><strong>Regular cleaning</strong> - We keep your home or apartment in perfect condition by providing periodic visits that fit your schedule.</li>
        <li><strong>One-time cleaning</strong> - We prepare your interiors for special occasions, after renovations or before important events.</li>
        <li><strong>Additional services</strong> - Washing windows, cleaning household appliances, washing carpets and upholstery, and much more.</li>
      </ul>
        `,
      },
      upholsteryCleaning: {
        title: 'Upholstery and Carpet Cleaning',
        description: `Your upholstered furniture and carpets are not only decorative pieces, but also places where you spend a lot of time.
        Over the years, they accumulate dust, stains, allergens and unpleasant odors.
        Our furniture upholstery and carpet washing service will restore them to freshness and make them look like new.
        
        <h3>Why use our service?</h3>
          <ul>
            <li><strong>Deep cleaning</strong> – Removes dirt and stains from the deep layers of the fabric without damaging the fabric structure.</li>
            <li><strong>Safe cleaning products</strong> – We use eco-friendly and safe detergents that are kind to your family and pets.</li>
            <li><strong>Professional equipment</strong> – We use modern equipment to ensure efficiency and speed of work.</li>
          </ul>`,
      },
      carCleaning: {
        title: 'Car Interior Cleaning',
        description: `Coming Soon!`,
        comingSoon: true,
      },
      drivewayCleaning: {
        title: 'Driveway Cleaning',
        description: `Coming Soon!`,
        comingSoon: true,
      },
    },
      individualApproach: `We are characterized by an individual approach to each client, as we believe that the foundation of good business is trust and full customer satisfaction. That is why we always listen to your needs and adapt our services to exceed your expectations.`,
      enjoyCleanliness: `Let us take care of your space and enjoy cleanliness at the highest level!`,
      contactUsToday: `Contact us today`,
      experienceTheDifference: ` and experience the difference that professional cleaning brings.`,
      viewMore: 'View More',
      comingSoon: 'Coming Soon!',
      contact: 'Contact',
      pricing: 'Pricing',
      gallery: 'Gallery',
      contact_us: "Contact Us",
      quick_links: 'Quick Links',
      follow_us: 'Follow Us',
      darkMode: 'Switch to Dark Mode',
      lightMode: 'Switch to Light Mode',
      pricingDetails: "Our Pricing",
    basicCleaning: "Basic Cleaning",
    additionalServices: "Additional Services",
    cleaningFurniture: "Cleaning Furniture and Carpets",
    selectServiceLabel: "Select a service:",
    allServices: "All services",
    basicCleaningDetails: {
      price: "Bathroom, kitchen, hallway, 1 room - 130 PLN",
      sections: {
        bathroom: {
          title: "Bathroom",
          price: "40 PLN",
          details: [
            "Vacuuming and mopping floors",
            "Wiping all surfaces",
            "Cleaning bathtub, faucet, sink, toilet, mirror",
          ].join(", ")
        },
        kitchen: {
          title: "Kitchen",
          price: "40 PLN",
          details: [
            "Vacuuming and mopping floors",
            "Wiping all surfaces (fridge, hood, appliances)",
            "Cleaning stove, sink, countertops",
            "Cleaning outside of furniture",
            "Cleaning tiles",
          ].join(", ")
        },
        room: {
          title: "Room",
          price: "30 PLN",
          details: [
            "Vacuuming and mopping floors",
            "Cleaning mirrors",
            "Wiping all surfaces",
            "Cleaning windowsills, handles, switches",
            "Collecting and disposing of garbage",
            "Making the bed",
            "Organizing and sorting things",
          ].join(", ")
        },
        hallway: {
          title: "Hallway",
          price: "20 PLN",
          details: [
            "Vacuuming and mopping floors",
            "Organizing shoes",
            "Taking out the trash",
            "Hanging and folding clothes",
            "Cleaning mirror",
          ].join(", ")
        },
      }
    },
    additionalServicesDetails: {
      kitchen: {
        title: "Kitchen",
        items: [
          { service: "Fridge cleaning", price: "25 PLN" },
          { service: "Hood cleaning", price: "25 PLN" },
          { service: "Oven cleaning", price: "25 PLN" },
          { service: "Microwave cleaning", price: "10 PLN" },
          { service: "Toaster cleaning", price: "10 PLN" },
          { service: "Kettle descaling and cleaning", price: "10 PLN" },
          { service: "Dishwashing", price: "15 PLN" },
          { service: "Cabinets inside cleaning", price: "40 PLN" },
          { service: "Cleaning doors", price: "15 PLN" }
        ]
      },
      bathroom: {
        title: "Bathroom",
        items: [
          { service: "Grout cleaning", price: "20 PLN" },
          { service: "Cleaning doors", price: "15 PLN" },
          { service: "Limescale removal", price: "29 PLN" }
        ]
      },
      room: {
        title: "Room",
        items: [
          { service: "Radiator cleaning", price: "20 PLN" },
          { service: "Washing crockery", price: "40 PLN" },
          { service: "Curtain replacement", price: "15 PLN" },
          { service: "Organizing inside the wardrobe", price: "20 PLN" },
          { service: "Cleaning doors", price: "15 PLN" }
        ]
      },
      others: {
        title: "Other Services",
        items: [
          { service: "Balcony cleaning", price: "15 PLN" },
          { service: "Cat litter box cleaning", price: "5 PLN" },
          { service: "Additional hour", price: "35 PLN" },
          { service: "Window cleaning (1 piece)", price: "25 PLN" },
          { service: "Cleaning doors", price: "15 PLN" }
        ]
      }
    },
    cleaningFurnitureDetails: {
      sofa: {
        title: "Sofas",
        items: [
          { service: "Two-seater", price: "100 PLN" },
          { service: "Three-seater", price: "110 PLN" }
        ]
      },
      cornerSofa: {
        title: "Corner Sofas",
        items: [
          { service: "4-seater", price: "120 PLN" },
          { service: "5-6 seater", price: "130 PLN" },
          { service: "7+ seater", price: "140 PLN" }
        ]
      },
      mattresses: {
        title: "Mattresses",
        items: [
          { service: "Single-sided single", price: "45 PLN" },
          { service: "Double-sided single", price: "90 PLN" },
          { service: "Single-sided double", price: "90 PLN" },
          { service: "Double-sided double", price: "180 PLN" }
        ]
      },
      carpets: {
        title: "Carpets and Rugs",
        items: [
          { service: "Per square meter", price: "7 PLN" }
        ]
      },
      chairs: {
        title: "Chairs and Stools",
        items: [
          { service: "Armchair", price: "30 PLN" },
          { service: "Chair", price: "12 PLN" },
          { service: "Stool", price: "10 PLN" },
          { service: "Office chair", price: "15 PLN" }
        ]
      }
    },
    galleryInfoText: "Below are the transformations. To view them, click on the image: ",
    galleryTitle: "Gallery",
    before: "BEFORE",
    after: "AFTER",
    contactText: 'If you have any questions or would like to schedule a service, feel free to contact us. Our team is ready to assist you with all your cleaning needs.',
      tableHeaders: ['Service', 'Price', 'Description'],
      tableData: [
        ['Sofa Cleaning', '$50', 'Cleaning of all types of sofas.'],
        ['Armchair Cleaning', '$30', 'Deep cleaning of armchairs.'],
        ['Carpet Cleaning', '$40', 'Carpet cleaning per square meter.']
      ],
      nameLabel: 'Name',
      emailLabel: 'Email',
      phoneLabel: 'Phone',
      messageLabel: 'Message',
      submitButton: 'Submit',
      successMessage: 'Your message has been sent!',
      errorMessage: 'There was an error sending your message.',
      nameLabelError: 'Name is required.',  // Komunikat błędu dla 'name'
      emailLabelError: 'Email is required.',  // Komunikat błędu dla 'email'
      emailInvalidError: 'Invalid email address.',  // Komunikat błędu dla nieprawidłowego emaila
      phoneLabelError: 'Phone number is required.',  // Komunikat błędu dla 'phone'
      phoneInvalidError: 'Phone number must be 9 digits.',  // Komunikat błędu dla nieprawidłowego numeru telefonu
      messageLabelError: 'Message is required.',  // Komunikat błędu dla 'message'
      offers: 'Offers',
      homeCleaningPage: {
        title: 'House and Apartment Cleaning',
        description: `House and apartment cleaning – we provide thorough cleaning of living spaces, tailored to your requirements and preferences. Our services include both daily cleaning, deep cleaning, preparing apartments for rent, or post-renovation cleaning.`
      },
      upholsteryCleaningPage: {
        title: 'Upholstery and Carpet Cleaning',
        description: `Upholstery cleaning – refreshing and cleaning upholstery is our specialty. We remove dirt, stains, and dust mites, restoring freshness and comfort to your furniture.`
      },
      carCleaningPage: {
        title: 'Car Interior Cleaning',
        description: `Car interior cleaning – we offer comprehensive cleaning of your vehicle's interior. We take care of upholstery, dashboards, and all details so you can enjoy a clean and fragrant car.`
      },
      sections: {
        basicCleaning: {
          title: 'Basic Cleaning',
          items: [
            { service: 'Bathroom, kitchen, hallway, 1 room', price: '130zł' },
            { service: 'Bathroom', price: '40zł' },
            { service: 'Room', price: '30zł' },
            { service: 'Hallway', price: '20zł' },
            { service: 'Kitchen', price: '40zł' },
          ],
        },
        additionalCleaning: {
          title: 'Additional - Kitchen',
          items: [
            { service: 'Refrigerator cleaning', price: '25zł' },
            { service: 'Hood cleaning', price: '25zł' },
            { service: 'Oven cleaning', price: '25zł' },
            { service: 'Microwave cleaning', price: '10zł' },
            { service: 'Toaster cleaning', price: '10zł' },
            { service: 'Kettle descaling', price: '10zł' },
            { service: 'Dishwashing', price: '15zł' },
            { service: 'Cabinets inside cleaning', price: '40zł' },
          ],
        },
        additionalBathroom: {
          title: 'Additional - Bathroom',
          items: [
            { service: 'Grout cleaning', price: '20zł' },
            { service: 'Limescale removal', price: '29zł' },
          ],
        },
        additionalRoom: {
          title: 'Additional - Room',
          items: [
            { service: 'Radiator cleaning', price: '20zł' },
            { service: 'Dishes cleaning', price: '40zł' },
            { service: 'Curtain replacement', price: '15zł' },
            { service: 'Wardrobe organizing', price: '20zł' },
          ],
        },
        otherServices: {
          title: 'Other Additional Services',
          items: [
            { service: 'Balcony cleaning', price: '15zł' },
            { service: 'Litter box cleaning', price: '5zł' },
            { service: 'Additional hour', price: '35zł' },
            { service: 'Window cleaning (per window)', price: '25zł' },
          ],
        },
        upholsteryCleaning: {
          title: 'Upholstery and Carpet Cleaning',
          items: [
            { service: 'Two-seater sofa', price: '100zł' },
            { service: 'Three-seater sofa', price: '110zł' },
            { service: 'Corner sofa (4-seater)', price: '120zł' },
            { service: 'Corner sofa (5-6 seater)', price: '130zł' },
            { service: 'Corner sofa (7+ seater)', price: '140zł' },
          ],
        },
        mattresses: {
          title: 'Mattresses',
          items: [
            { service: 'Single-sided (single)', price: '45zł' },
            { service: 'Double-sided (single)', price: '90zł' },
            { service: 'Single-sided (double)', price: '90zł' },
            { service: 'Double-sided (double)', price: '180zł' },
          ],
        },
        carpets: {
          title: 'Carpets and Rugs',
          items: [
            { service: 'Per square meter', price: '7zł' },
          ],
        },
        chairs: {
          title: 'Chairs and Armchairs',
          items: [
            { service: 'Armchair', price: '30zł' },
            { service: 'Chair', price: '12zł' },
            { service: 'Stool', price: '10zł' },
            { service: 'Office chair', price: '15zł' },
          ],
        },
      },
      paymentSectionTitle: "Simple and Convenient Payments",
    paymentDescription: `We make sure that cooperation with our company is as comfortable and hassle-free as possible. That's why we offer simple and secure ways of settlement - payment by cash, card and transfer to our account after the service is performed.
                          
                          If you have any questions about payment or our services, we are at your service`,
      contactTitle: "Contact",
      contactInfoTitle: "Contact Information",
      address: "Address:",
      phone: "Phone:",
      email: "Email:",
      facebook: "Facebook:",
      nip: "NIP:",
      legalInfoTitle: "Legal Information",
      legalInfoText: "The website owner and service provider is Fresh Panda. All rights reserved. Please contact us if you have any questions about our services or privacy policy.",
      openingHoursTitle: "Opening Hours",
      openingHours: [
        "Monday - Friday: 8:00 - 20:00",
        "Saturday: 8:00 - 20:00",
        "Sunday: 8:00 - 20:00"
      ],
      contactDetails: {
        // address: "ul. Kolorowa 18, 80-288 Gdańsk",
        phone: "+48570449898",
        email: "freshpanda.info@gmail.com",
        facebook: "Fresh Panda",
        nip: "5833522021"
      },
      transformationsTitle: "Transformations",
      noTransformationsAvailable: "Transformations are not available. ",
      // transformations: {
      //   homeCleaning: [
      //     {
      //       beforeImage: beforeImageSofa,
      //       afterImage: afterImageSofa,
      //     },
      //     {
      //       beforeImage: beforeImageSara,
      //       afterImage: afterImageSara,
      //     },
      //     {
      //       beforeImage: beforeImageSofa,
      //       afterImage: afterImageSofa,
      //     },
      //     {
      //       beforeImage: beforeImageSofa,
      //       afterImage: afterImageSofa,
      //     },
      //     {
      //       beforeImage: beforeImageSofa,
      //       afterImage: afterImageSofa,
      //     },
      //     // Dodaj więcej metamorfoz dla sprzątania domów
      //   ],
      //   upholsteryCleaning: [
      //     {
      //       beforeImage: beforeImageSara,
      //       afterImage: afterImageSara,
      //     },
      //     {
      //       beforeImage: beforeImageSara,
      //       afterImage: afterImageSara,
      //     },
      //     {
      //       beforeImage: beforeImageSara,
      //       afterImage: afterImageSara,
      //     },
      //     {
      //       beforeImage: beforeImageSara,
      //       afterImage: afterImageSara,
      //     },
      //     // Dodaj więcej metamorfoz dla prania tapicerki
      //   ],
      //   // Dodaj pozostałe usługi
      // },
      pricingOfServices: "Pricing of Services",
    pricingOfServicesFor: "Pricing of Services for",
    },
    pl: {
      home: 'Strona Główna',
      serviceAreaTextLine1: "Działamy na terenie",
    serviceAreaTextLine2: "Trójmiasta i okolic",  
    introText: `Witaj w naszej firmie sprzątającej, gdzie czystość spotyka się z profesjonalizmem. Specjalizujemy się w kompleksowych usługach sprzątających, dopasowanych do Twoich potrzeb. Nasz zespół doświadczonych specjalistów zadba o to, by Twój dom, mieszkanie, meble tapicerowane, a nawet wnętrze auta lśniły czystością.`,
    ourOfferIncludes: 'Nasza oferta obejmuje:',
    services: {
      homeCleaning: {
        title: 'Sprzątanie domów i mieszkań',
        description: `
      <p><strong>Nasza usługa sprzątania domów i mieszkań to gwarancja nieskazitelnej czystości, komfortu i zadowolenia z Twojego otoczenia.</strong> Nasi profesjonalni pracownicy zadbają o każdy, nawet najmniejszy detal, dostosowując zakres prac do Twoich indywidualnych potrzeb i preferencji. Rozumiemy, jak cenny jest Twój czas, dlatego oferujemy elastyczne terminy oraz kompleksowe rozwiązania, aby ułatwić Ci codzienne życie.
      Pozwól nam odciążyć Cię od codziennych obowiązków, abyś mógł cieszyć się wolnym czasem w czystym, przytulnym i zdrowym otoczeniu. <strong>Skontaktuj się z nami już dziś</strong> i przekonaj się, jak możemy uczynić Twoje życie łatwiejszym!</p>
      
      <h3>Co oferujemy?</h3>
      <ul>
        <li><strong>Regularne sprzątanie</strong> – Utrzymujemy Twój dom lub mieszkanie w idealnym stanie, zapewniając cykliczne wizyty dostosowane do Twojego harmonogramu.</li>
        <li><strong>Sprzątanie jednorazowe</strong> – Przygotowujemy Twoje wnętrza na specjalne okazje, po remontach lub przed ważnymi wydarzeniami.</li>
        <li><strong>Usługi dodatkowe</strong> – Mycie okien, czyszczenie sprzętu AGD, pranie dywanów i tapicerki oraz wiele innych.</li>
      </ul>
    `,
    // ... inne dane
  },
      upholsteryCleaning: {
        title: 'Pranie tapicerki meblowej i dywanów',
        description: `Twoje meble tapicerowane i dywany to nie tylko elementy dekoracyjne, ale także miejsca, w których spędzasz wiele czasu.
        Z biegiem lat gromadzą one kurz, plamy, alergeny i nieprzyjemne zapachy.
        Nasza usługa prania tapicerki meblowej i dywanów przywróci im świeżość i sprawi, że będą wyglądały jak nowe.
        
        <h3>Dlaczego warto skorzystać z naszej usługi?</h3>
          <ul>
            <li><strong>Głębokie czyszczenie</strong> – Usuwamy brud i plamy z głębokich warstw materiału, nie niszcząc przy tym struktury tkaniny.</li>
            <li><strong>Bezpieczne środki czystości</strong> – Używamy ekologicznych i bezpiecznych detergentów, które są przyjazne dla Twojej rodziny i zwierząt domowych.</li>
            <li><strong>Profesjonalny sprzęt</strong> – Korzystamy z nowoczesnych urządzeń, które zapewniają skuteczność i szybkość pracy.</li>
          </ul>`,
      },
      carCleaning: {
        title: 'Czyszczenie wnętrza aut',
        description: `Wkrótce w ofercie.`,
        comingSoon: true,
      },
      drivewayCleaning: {
        title: 'Czyszczenie kostki brukowej',
        description: `Wkrótce w ofercie.`,
        comingSoon: true,
      },
    },
    individualApproach: `Cechuje nas indywidualne podejście do każdego klienta, ponieważ wierzymy, że fundamentem dobrego biznesu jest zaufanie i pełne zadowolenie naszych klientów. Dlatego zawsze wsłuchujemy się w Twoje potrzeby i dostosowujemy nasze usługi, aby przekroczyć Twoje oczekiwania.`,
    enjoyCleanliness: `Pozwól nam zadbać o Twoją przestrzeń i ciesz się czystością na najwyższym poziomie!`,
    contactUsToday: `Skontaktuj się z nami`,
    experienceTheDifference: ` już dziś i doświadcz różnicy, jaką niesie profesjonalne sprzątanie.`,
    viewMore: 'Zobacz więcej',
    comingSoon: 'Wkrótce w ofercie',
    contact: 'Kontakt',
    pricingDetails: "Nasz Cennik",
    selectServiceLabel: "Wybierz usługę:",
    allServices: "Wszystkie usługi",
    pricing: 'Cennik',
    gallery: 'Galeria',
    contact_us: "Skontaktuj się z nami",
    quick_links: 'Szybkie Odnośniki',
    follow_us: 'Obserwuj Nas',
    darkMode: 'Przełącz na tryb ciemny',
    lightMode: 'Przełącz na tryb jasny',
    galleryInfoText: "Poniżej przedstawiono metamorfozy. Aby je zobaczyć, kliknij na zdjęcie: ",
    galleryTitle: "Galeria",
    before: "PRZED",
    after: "PO",
      contactText: 'Jeśli masz pytania lub chcesz umówić się na usługę, skontaktuj się z nami. Nasz zespół jest gotowy, aby pomóc Ci we wszystkich potrzebach związanych z czyszczeniem.',
      tableHeaders: ['Usługa', 'Cena', 'Opis'],
      tableData: [
        ['Czyszczenie sofy', '50 zł', 'Czyszczenie wszystkich rodzajów sof.'],
        ['Czyszczenie fotela', '30 zł', 'Głębokie czyszczenie foteli.'],
        ['Czyszczenie dywanu', '40 zł', 'Czyszczenie dywanu za metr kwadratowy.']
      ],
      nameLabel: 'Imię i Nazwisko',
      emailLabel: 'Email',
      phoneLabel: 'Telefon',
      messageLabel: 'Wiadomość',
      submitButton: 'Wyślij',
      successMessage: 'Wiadomość została wysłana!',
      errorMessage: 'Wystąpił błąd podczas wysyłania formularza.',
      nameLabelError: 'Imię i nazwisko jest wymagane.',  // Komunikat błędu dla 'name'
      emailLabelError: 'Email jest wymagany.',  // Komunikat błędu dla 'email'
      emailInvalidError: 'Nieprawidłowy adres e-mail.',  // Komunikat błędu dla nieprawidłowego emaila
      phoneLabelError: 'Numer telefonu jest wymagany.',  // Komunikat błędu dla 'phone'
      phoneInvalidError: 'Numer telefonu musi składać się z 9 cyfr.',  // Komunikat błędu dla nieprawidłowego numeru telefonu
      messageLabelError: 'Wiadomość jest wymagana.',  // Komunikat błędu dla 'message'
      offers: 'Oferta',
      homeCleaningTitle: 'Sprzątanie domów i mieszkań',
      homeCleaningDescription: 'Opis szczegółowy oferty sprzątania domów i mieszkań...',
      carCleaningTitle: 'Czyszczenie wnętrza aut',
      carCleaningDescription: 'Opis szczegółowy oferty czyszczenia wnętrza aut...',
      drivewayCleaningTitle: 'Czyszczenie kostki brukowej',
      drivewayCleaningDescription: 'Opis szczegółowy oferty czyszczenia kostki brukowej...',
    basicCleaning: "Sprzątanie Podstawowe",
    additionalServices: "Usługi Dodatkowe",
    cleaningFurniture: "Pranie Kanap i Dywanów",
    basicCleaningDetails: {
      price: "Łazienka, kuchnia, przedpokój, 1 pokój - 130 zł",
      sections: {
        bathroom: {
          title: "Łazienka",
          price: "40 zł",
          details: [
            "Odkurzanie i mycie podłóg",
            "Przecieranie wszystkich powierzchni",
            "Mycie wanny, kranu, zlewu, sedesu, lustra",
          ].join(", ")
        },
        kitchen: {
          title: "Kuchnia",
          price: "40 zł",
          details: [
            "Odkurzanie i mycie podłóg",
            "Przecieranie wszystkich powierzchni (lodówka, okap, AGD)",
            "Mycie kuchenki, zlewu i blatów",
            "Mycie mebli z zewnątrz",
            "Mycie płytek",
          ].join(", ")
        },
        room: {
          title: "Pokój",
          price: "30 zł",
          details: [
            "Odkurzanie i mycie podłóg",
            "Czyszczenie luster",
            "Wycieranie wszystkich powierzchni",
            "Mycie parapetów, klamek, włączników",
            "Zebranie i wyrzucenie śmieci",
            "Ścielenie łóżka",
            "Układanie i segregowanie rzeczy",
          ].join(", ")
        },
        hallway: {
          title: "Przedpokój",
          price: "20 zł",
          details: [
            "Odkurzanie i mycie podłóg",
            "Układanie butów",
            "Wyniesienie śmieci",
            "Rozwieszanie i składanie odzieży",
            "Mycie lustra",
          ].join(", ")
        },
      }
    },
    additionalServicesDetails: {
      kitchen: {
        title: "Kuchnia",
        items: [
          { service: "Czyszczenie lodówki", price: "25 zł" },
          { service: "Mycie okapu", price: "25 zł" },
          { service: "Mycie piekarnika", price: "25 zł" },
          { service: "Mycie mikrofalówki", price: "10 zł" },
          { service: "Mycie opiekacza", price: "10 zł" },
          { service: "Mycie i odkamienianie czajnika", price: "10 zł" },
          { service: "Mycie naczyń", price: "15 zł" },
          { service: "Szafki wewnątrz", price: "40 zł" },
          { service: "Mycie drzwi", price: "15 zł" },
        ]
      },
      bathroom: {
        title: "Łazienka",
        items: [
          { service: "Mycie fug", price: "20 zł" },
          { service: "Usunięcie kamienia", price: "29 zł" },
          { service: "Mycie drzwi", price: "15 zł" },
        ]
      },
      room: {
        title: "Pokój",
        items: [
          { service: "Mycie kaloryfera", price: "20 zł" },
          { service: "Umycie zastawy", price: "40 zł" },
          { service: "Wymiana zasłon", price: "15 zł" },
          { service: "Ułożenie wewnątrz szafy", price: "20 zł" },
          { service: "Mycie drzwi", price: "15 zł" },
        ]
      },
      others: {
        title: "Inne Usługi",
        items: [
          { service: "Sprzątanie balkonu", price: "15 zł" },
          { service: "Sprzątanie kuwety", price: "5 zł" },
          { service: "Dodatkowa godzina", price: "35 zł" },
          { service: "Mycie okien (1 szt.)", price: "25 zł" }
        ]
      }
    },
    cleaningFurnitureDetails: {
      sofa: {
        title: "Kanapy",
        items: [
          { service: "Dwuosobowa", price: "100 zł" },
          { service: "Trzyosobowa", price: "110 zł" }
        ]
      },
      cornerSofa: {
        title: "Narożniki",
        items: [
          { service: "4-osobowy", price: "120 zł" },
          { service: "5-6 osobowy", price: "130 zł" },
          { service: "7+ osobowy", price: "140 zł" }
        ]
      },
      mattresses: {
        title: "Materace",
        items: [
          { service: "Jednoosobowy jednostronny", price: "45 zł" },
          { service: "Jednoosobowy dwustronny", price: "90 zł" },
          { service: "Dwuosobowy jednostronny", price: "90 zł" },
          { service: "Dwuosobowy dwustronny", price: "180 zł" }
        ]
      },
      carpets: {
        title: "Dywany i Wykładziny",
        items: [
          { service: "Za metr kwadratowy", price: "7 zł" }
        ]
      },
      chairs: {
        title: "Fotele i Krzesła",
        items: [
          { service: "Fotel", price: "30 zł" },
          { service: "Krzesło", price: "12 zł" },
          { service: "Taboret", price: "10 zł" },
          { service: "Fotel biurowy", price: "15 zł" }
        ]
      }
    },
  
      paymentSectionTitle: "Proste i Wygodne Płatności",
      paymentDescription: `Dbamy o to, aby współpraca z naszą firmą była jak najbardziej komfortowa i bezproblemowa. Dlatego oferujemy proste i bezpieczne sposoby rozliczeń – płatność gotówką, kartą oraz przelew na konto po wykonaniu usługi.

                          Jeśli masz jakiekolwiek pytania dotyczące płatności lub naszych usług, jesteśmy do Twojej dyspozycji.`,
      contactTitle: "Kontakt",
      contactInfoTitle: "Informacje Kontaktowe",
      address: "Adres:",
      phone: "Telefon:",
      email: "Email:",
      facebook: "Facebook:",
      nip: "NIP:",
      legalInfoTitle: "Informacje Prawne",
      legalInfoText: "Właścicielem strony internetowej i dostawcą usług jest Firma Fresh Panda. Wszelkie prawa zastrzeżone. Prosimy o kontakt w przypadku jakichkolwiek pytań dotyczących naszych usług lub polityki prywatności.",
      openingHoursTitle: "Godziny Otwarcia",
      openingHours: [
        "Poniedziałek - Piątek: 08:00 - 20:00",
        "Sobota: 8:00 - 20:00",
        "Niedziela: 8:00 - 20:00"
      ],
      contactDetails: {
        // address: " ul. Kolorowa 18, 80-288 Gdańsk",
        phone: "+48570449898",
        email: "freshpanda.info@gmail.com",
        facebook: "Fresh Panda",
        nip: "5833522021"
      },
      transformationsTitle: "Metamorfozy",
      noTransformationsAvailable: "Brak dostępnych metamorfoz.",
      // transformations: {
      //   homeCleaning: [
      //     {
      //       beforeImage: beforeImageSofa,
      //       afterImage: afterImageSofa,
      //     },
      //     {
      //       beforeImage: beforeImageSofa,
      //       afterImage: afterImageSofa,
      //     },
      //     {
      //       beforeImage: beforeImageSofa,
      //       afterImage: afterImageSofa,
      //     },
      //     {
      //       beforeImage: beforeImageSofa,
      //       afterImage: afterImageSofa,
      //     },
      //     {
      //       beforeImage: beforeImageSara,
      //       afterImage: afterImageSara,
      //     },
      //     // Dodaj więcej metamorfoz dla sprzątania domów
      //   ],
      //   upholsteryCleaning: [
      //     {
      //       beforeImage: beforeImageSara,
      //       afterImage: afterImageSara,
      //     },
      //     {
      //       beforeImage: beforeImageSara,
      //       afterImage: afterImageSara,
      //     },
      //     {
      //       beforeImage: beforeImageSara,
      //       afterImage: afterImageSara,
      //     },
      //     {
      //       beforeImage: beforeImageSara,
      //       afterImage: afterImageSara,
      //     },
      //     // Dodaj więcej metamorfoz dla prania tapicerki
      //   ],
      //   // Dodaj pozostałe usługi
      // },
      pricingOfServices: "Cennik usług",
      pricingOfServicesFor: "Cennik usług dla",
    },
  };
  
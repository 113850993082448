// Gallery.js
import React, { useState, useEffect } from 'react';
import ImageComparison from './ImageComparison';
import { useAppContext } from '../../contexts/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import '../../styles/Gallery.css';

function Gallery({ selectedService = null, carouselMode = false }) {
  const { t } = useAppContext();

  // Fetch transformations
  let transformations = [];

  if (selectedService) {
    const serviceTransformations = t.transformations?.[selectedService];
    if (serviceTransformations) {
      transformations = serviceTransformations;
    }
  } else {
    if (t.transformations) {
      Object.values(t.transformations).forEach((serviceTransformations) => {
        transformations = transformations.concat(serviceTransformations);
      });
    }
  }

  // Move Hooks to the top level
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-play functionality
  useEffect(() => {
    if (carouselMode && transformations.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % transformations.length
        );
      }, 10000); // Change every 10 seconds

      return () => clearInterval(interval);
    }
  }, [carouselMode, transformations.length]);

  // Handlers for manual navigation
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? transformations.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % transformations.length);
  };

  // Early return if no transformations
  if (transformations.length === 0) {
    return (
      <p>{t.noTransformationsAvailable || 'Brak dostępnych metamorfoz.'}</p>
    );
  }

  return (
    <div className="gallery-container">
      {/* Gallery Info Section */}
      <div className="gallery-info">
        <p>
          {t.galleryInfoText}
          <FontAwesomeIcon icon={faCamera} className="icon" />
        </p>
      </div>

      <h2>{t.galleryTitle}</h2>

      {carouselMode ? (
        // Custom Carousel Mode
        <div className="carousel-container">
          <div className="carousel-slide">
            <ImageComparison
              beforeImage={transformations[currentIndex].beforeImage}
              afterImage={transformations[currentIndex].afterImage}
              labels={{ before: t.before, after: t.after }}
            />
          </div>
          {transformations.length > 1 && (
            <div className="carousel-controls">
              <button onClick={goToPrevious} aria-label="Previous">
                ❮
              </button>
              <button onClick={goToNext} aria-label="Next">
                ❯
              </button>
            </div>
          )}
        </div>
      ) : (
        // Standard Mode - Display all transformations
        <div className="comparison-section">
          {transformations.map((item, index) => (
            <ImageComparison
              key={index}
              beforeImage={item.beforeImage}
              afterImage={item.afterImage}
              labels={{ before: t.before, after: t.after }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Gallery;

// Header.js
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, 
         faTimes, 
        //  faHome, 
         faPhoneAlt, 
         faEnvelope, 
         faMoon, 
         faSun } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons'; // Import ikony Facebooka
import { useAppContext } from '../../contexts/context'; // Adjust path as needed
import logo from '../../assets/logo.png'; // Adjust path as needed
import '../../styles/Header.css'; // Import the header-specific CSS

function Header() {
  const { darkMode, toggleDarkMode, toggleLanguage, t } = useAppContext();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="app-header">
      {/* Informacje o obszarze usług */}
      <div className="service-area">
        <p>
          {t.serviceAreaTextLine1} <br />
          <strong>{t.serviceAreaTextLine2}</strong>
        </p>
      </div>

      {/* Logo */}
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="Logo" className="app-logo" />
        </Link>
      </div>

      {/* Przycisk menu mobilnego */}
      <button className="mobile-menu-button" onClick={handleMobileMenuToggle}>
        <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
      </button>

      {/* Menu nawigacyjne */}
      <nav className={`app-nav ${isMobileMenuOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <Link to="/" onClick={() => setMobileMenuOpen(false)}>
              {t.home}
            </Link>
          </li>
          <li className="dropdown" ref={dropdownRef}>
            <button onClick={handleDropdownToggle} className="dropdown-button">
              {t.offers}
            </button>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <Link to="/home-cleaning" onClick={() => { setDropdownOpen(false); setMobileMenuOpen(false); }}>
                    {t.services.homeCleaning.title}
                  </Link>
                </li>
                <li>
                  <Link to="/upholstery-cleaning" onClick={() => { setDropdownOpen(false); setMobileMenuOpen(false); }}>
                    {t.services.upholsteryCleaning.title}
                  </Link>
                </li>
                <li>
                  <Link to="/car-cleaning" onClick={() => { setDropdownOpen(false); setMobileMenuOpen(false); }}>
                    {t.services.carCleaning.title}
                  </Link>
                </li>
                <li>
                  <Link to="/driveway-cleaning" onClick={() => { setDropdownOpen(false); setMobileMenuOpen(false); }}>
                    {t.services.drivewayCleaning.title}
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link to="/pricing" onClick={() => setMobileMenuOpen(false)}>
              {t.pricing}
            </Link>
          </li>
          <li>
            <Link to="/gallery" onClick={() => setMobileMenuOpen(false)}>
              {t.gallery}
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={() => setMobileMenuOpen(false)}>
              {t.contact}
            </Link>
          </li>
        </ul>
      </nav>

      {/* Sekcja z informacjami kontaktowymi i kontrolkami */}
      <div className="header-right">
        {/* Informacje kontaktowe */}
        <div className="contact-details">
          {/* <div className="header-info">
            <FontAwesomeIcon icon={faHome} className="header-icon" />
            <span>ul. Kolorowa 18, 80-288 Gdańsk</span>
          </div> */}
          <div className="header-info">
            <FontAwesomeIcon icon={faPhoneAlt} className="header-icon" />
            <a href="tel:+48570449898">{t.contactDetails.phone}</a>
          </div>
          <div className="header-info">
            <FontAwesomeIcon icon={faEnvelope} className="header-icon" />
            <a href="mailto:freshpanda.info@gmail.com">{t.contactDetails.email}</a>
          </div>
          <div className="header-info">
            <FontAwesomeIcon icon={faFacebook} className="header-icon" />
            <a
              href="https://www.facebook.com/profile.php?id=61564148421249"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              Fresh Panda
            </a>
          </div>
        </div>
        {/* Przełącznik trybu ciemnego i zmiana języka */}
        <div className="header-controls">
          <button onClick={toggleDarkMode} className="theme-toggle-button">
            <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
          </button>
          <div className="language-switch">
            <button onClick={() => toggleLanguage('pl')} className="flag-button">
              <span role="img" aria-label="Polish flag" className="flag-icon">
                🇵🇱
              </span>
            </button>
            <button onClick={() => toggleLanguage('en')} className="flag-button">
              <span role="img" aria-label="UK flag" className="flag-icon">
                🇬🇧
              </span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

import React from 'react';
import { useAppContext } from '../../contexts/context'; // Importujemy kontekst do zarządzania tłumaczeniami
import '../../styles/Contact.css';
function Contact() {
  const { t } = useAppContext(); // Użycie kontekstu do uzyskania tłumaczeń

  return (
    <div className="contact-container">
      <h2>{t.contactTitle}</h2>

      {/* Kontener na informacje kontaktowe i godziny otwarcia */}
      <div className="contact-info-container">
        {/* Informacje kontaktowe */}
        <section className="contact-info">
          <h3>{t.contactInfoTitle}</h3>
          {/* <p><strong>{t.address}</strong>{t.contactDetails.address}</p> */}
          <p><strong>{t.phone}</strong> <a href="tel:+48570449898">{t.contactDetails.phone}</a></p>
          <p><strong>{t.email}</strong> <a href="mailto:freshpanda.info@gmail.com">{t.contactDetails.email}</a></p>
          <p><strong>{t.facebook}</strong> <a href="https://www.facebook.com/profile.php?id=61564148421249">{t.contactDetails.facebook}</a></p>
          <p><strong>{t.nip}</strong> {t.contactDetails.nip}</p>
        </section>

        {/* Godziny otwarcia */}
        <section className="opening-hours">
          <h3>{t.openingHoursTitle}</h3>
          <ul>
            {t.openingHours.map((hour, index) => (
              <li key={index}>{hour}</li>
            ))}
          </ul>
        </section>
      </div>

      {/* Dodatkowe informacje prawne */}
      <section className="legal-info">
        <h3>{t.legalInfoTitle}</h3>
        <p>{t.legalInfoText}</p>
      </section>
    </div>
  );
}

export default Contact;

// CarCleaning.js
import React from 'react';
import { useAppContext } from '../../contexts/context';

function CarCleaning() {
  const { t } = useAppContext();  // Uzyskiwanie tłumaczeń z kontekstu

  // Sprawdzenie dostępności danych
  const service = t.services?.carCleaning;

  if (!service) {
    return <p>Data not available</p>;  // Fallback w przypadku braku danych
  }

  return (
    <div>
      <h2>{service.title}</h2>
      <p>{service.description}</p>
      {/* Możesz dodać tutaj więcej treści i stylizacji według potrzeb */}
    </div>
  );
}

export default CarCleaning;

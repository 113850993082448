import React, { createContext, useState, useContext, useEffect } from 'react';
import { translations } from '../locales/translations';  // Zaktualizowany import

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const [language, setLanguage] = useState('pl');

  const toggleDarkMode = () => {
    setDarkMode(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('dark-mode', newMode);
      return newMode;
    });
  };

  const toggleLanguage = (lang) => setLanguage(lang);

  useEffect(() => {
    const savedTheme = localStorage.getItem('dark-mode');
    if (savedTheme !== null) {
      setDarkMode(savedTheme === 'true');
    } else {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setDarkMode(prefersDark);
    }
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  const t = translations[language];

  return (
    <AppContext.Provider value={{ darkMode, toggleDarkMode, language, toggleLanguage, t }}>
      {children}
    </AppContext.Provider>
  );
};

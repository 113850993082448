// HomeCleaning.js
import React from 'react';
import { useAppContext } from '../../contexts/context';
import Pricing from './Pricing';
import Gallery from '../ui/Gallery';
import '../../styles/HomeCleaning.css'; // Upewnij się, że plik istnieje
import homeCleaningImage from '../../assets/home-cleaning.jpg';

function HomeCleaning() {
  const { t } = useAppContext();
  const service = t.services?.homeCleaning;

  if (!service) {
    return <p>Dane nie są dostępne</p>;
  }

  return (
    <div>
      {/* Sekcja z opisem i obrazem obok siebie */}
      <div className="home-cleaning-container">
        <div className="home-cleaning-content">
          <h2>{service.title}</h2>
          <p dangerouslySetInnerHTML={{ __html: service.description }}></p>
        </div>
        <div className="home-cleaning-image">
          <img src={homeCleaningImage} alt={service.title} />
        </div>
      </div>

      {/* Kontener dla cennika i galerii obok siebie */}
      <div className="pricing-and-gallery">
      <div className="gallery-section">
          {/* Przekazujemy `carouselMode={true}` */}
          <Gallery selectedService="homeCleaning" carouselMode={true} />
        </div>
        <div className="pricing-section">
          <Pricing selectedService="homeCleaning" />
        </div>
      </div>
    </div>
  );
}

export default HomeCleaning;

// Home.js
import React from 'react';
import { useAppContext } from '../../contexts/context';
import { Link } from 'react-router-dom';  // Import Link component
import '../../styles/Home.css';

// Import images
import homeCleaning from '../../assets/home-cleaning.jpg';
import upholsteryCleaning from '../../assets/upholstery-cleaning.jpg';
import carCleaning from '../../assets/car-cleaning.jpg';
import drivewayCleaning from '../../assets/driveway-cleaning.jpg';

const serviceImages = {
  homeCleaning,
  upholsteryCleaning,
  carCleaning,
  drivewayCleaning,
};

function Home() {
  const { t } = useAppContext();

  const services = Object.keys(t.services).map((key) => {
    const service = t.services[key];
    return {
      ...service,
      imageUrl: serviceImages[key],
      link: `/${key.replace(/([A-Z])/g, '-$1').toLowerCase()}`,
      available: !service.comingSoon,
    };
  });

  return (
    <div className="home-container">
      {/* Introductory text section */}
      <section className="intro-text">
        <h2>{t.home}</h2>
        <p>{t.introText}</p>
        <h3 className="offer-title">{t.ourOfferIncludes}</h3>
      </section>

      {/* Services tiles section */}
      <div className="tiles-container">
        {services.map((service, index) => (
          <Link to={service.link} key={index} className={`service-tile ${service.available ? '' : 'unavailable'}`} style={{ backgroundImage: `url(${service.imageUrl})` }}>
            <div className="service-content">
              <h3 className="service-title">{service.title}</h3>
              {!service.available && <p className="coming-soon">{t.comingSoon}</p>}
              {service.available && <button className="details-link">{t.viewMore}</button>}
            </div>
          </Link>
        ))}
      </div>

      {/* Additional information and CTA */}
      <section className="additional-info">
        <p>{t.individualApproach}</p>
        <p>{t.enjoyCleanliness}</p>
        <p>
          <Link to="/contact" className="contact-link">{t.contactUsToday}</Link>{t.experienceTheDifference}
        </p>
      </section>
    </div>
  );
}

export default Home;

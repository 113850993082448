import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons';
import '../../styles/ImageComparison.css';

function ImageComparison({ beforeImage, afterImage, labels, caption }) {
  const [dividerPosition, setDividerPosition] = useState(50); // Pozycja suwaka w środku
  const [isOpen, setIsOpen] = useState(false); // Stan otwartego widoku porównawczego
  const containerRef = useRef(null); // Referencja do kontenera porównania
  const isDragging = useRef(false); // Stan przeciągania suwaka

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setIsOpen(false); // Zamknięcie widoku po naciśnięciu "Escape"
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen]);

  const handleMove = (clientX) => {
    if (!containerRef.current) return;
    const rect = containerRef.current.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const newPosition = Math.max(0, Math.min(100, (offsetX / rect.width) * 100)); // Zablokowanie między 0 a 100
    setDividerPosition(newPosition);
  };

  const handleMouseMove = (e) => {
    if (isDragging.current) {
      handleMove(e.clientX);
    }
  };

  const handleTouchMove = (e) => {
    handleMove(e.touches[0].clientX);
  };

  const handleMouseDown = () => {
    isDragging.current = true;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = () => {
    isDragging.current = false;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleTouchStart = () => {
    isDragging.current = true;
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  };

  const handleTouchEnd = () => {
    isDragging.current = false;
    document.removeEventListener('touchmove', handleTouchMove);
    document.removeEventListener('touchend', handleTouchEnd);
  };

  const toggleComparisonView = () => {
    setIsOpen((prev) => !prev); // Przełączanie widoku
  };

  return (
    <div className="image-comparison-wrapper">
      {/* Miniatura wyświetlająca obraz "po" */}
      {!isOpen && (
        <button
          className="image-thumbnail"
          onClick={toggleComparisonView}
          onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && toggleComparisonView()}
          aria-label="Otwórz porównanie zdjęć"
        >
          <img src={afterImage} alt={labels.after} className="thumbnail-image" />
        </button>
      )}

      {/* Widok porównania zdjęć otwierający się po kliknięciu miniatury */}
      {isOpen && (
        <div
          className="image-comparison-container"
          ref={containerRef}
          role="dialog"
          aria-label="Suwak porównania zdjęć"
        >
          {/* Kontener zdjęć, które mają być nałożone na siebie */}
          <div className="comparison-images">
            <img src={beforeImage} alt={labels.before} className="image-comparison-before" />

            <div
              className="image-comparison-after"
              style={{ clipPath: `inset(0 0 0 ${dividerPosition}%)` }}
            >
              <img src={afterImage} alt={labels.after} className="image-comparison-after-img" />
            </div>

            {/* Dodaj te elementy dla napisów "przed" i "po" */}
            <div className="image-comparison-label image-comparison-label-before">{labels.before}</div>
            <div className="image-comparison-label image-comparison-label-after">{labels.after}</div>

            <button
              className="image-comparison-divider"
              style={{ left: `${dividerPosition}%` }}
              onMouseDown={handleMouseDown}
              onTouchStart={handleTouchStart}
              role="slider"
              aria-valuenow={dividerPosition}
              aria-valuemin="0"
              aria-valuemax="100"
              aria-label="Przeciągnij, aby porównać obrazy"
            >
              <FontAwesomeIcon icon={faArrowsAltH} className="image-comparison-divider-icon" />
            </button>
          </div>


          {/* Przycisk do zamknięcia widoku porównania */}
          <button
            className="image-comparison-close"
            onClick={toggleComparisonView}
            onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && toggleComparisonView()} // Poprawiona obsługa klawiatury
            aria-label="Zamknij porównanie zdjęć"
          >
            Zamknij
          </button>
        </div>
      )}

      <div className="image-comparison-caption">{caption}</div> {/* Dodany podpis */}
    </div>
  );
}

export default ImageComparison;

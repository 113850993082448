// Footer.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for internal navigation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  // faTwitter,
  // faInstagram,
} from '@fortawesome/free-brands-svg-icons'; // Import social media icons
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'; // Import contact icons
import '../../styles/Footer.css'; // Import the CSS file for styling
import { useAppContext } from '../../contexts/context'; // Import context for translations

function Footer() {
  const { t } = useAppContext(); // Use context for translations
  return (
    <footer className="app-footer">
      <div className="footer-content">
        {/* Contact Information */}
        <div className="footer-section footer-contact">
          <h3>{t.contact_us}</h3>
          <p>
            <FontAwesomeIcon icon={faPhoneAlt} className="footer-icon" />{' '}
            <a href="tel:+48570449898">{t.contactDetails.phone}</a>
          </p>
          <p>
            <FontAwesomeIcon icon={faEnvelope} className="footer-icon" />{' '}
            <a href="mailto:freshpanda.info@gmail.com">{t.contactDetails.email}</a>
          </p>
        </div>

        {/* Quick Links */}
        <div className="footer-section footer-links">
          <h3>{t.quick_links}</h3>
          <ul>
            {/* <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li> */}
            {/* <li>
              <Link to="/terms">Terms of Service</Link>
            </li> */}
            <li>
              <Link to="/contact">{t.contact}</Link>
            </li>
            <li>
              <Link to="/pricing">{t.pricing}</Link>
            </li>
            <li>
              <Link to="/gallery">{t.gallery}</Link>
            </li>
          </ul>
        </div>

        {/* Social Media Icons */}
        <div className="footer-section footer-social">
          <h3>{t.follow_us}</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61564148421249" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookF} className="social-icon" />
            </a>
            {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} className="social-icon" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="social-icon" />
            </a> */}
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>&copy; 2024 Fresh Panda. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;

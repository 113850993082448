// Pricing.js
import React, { useState } from 'react';
import { useAppContext } from '../../contexts/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import '../../styles/Pricing.css';

function Pricing({ selectedService = null }) {
  const { t } = useAppContext();
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleCategorySelect = (category) => {
    setSelectedCategory(category === selectedCategory ? '' : category); // Przełączanie wybranej kategorii
  };

  const handleKeyDown = (event, category) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleCategorySelect(category);
    }
  };

  // Mapowanie usług na kategorie cennika
  const servicePricingMap = {
    homeCleaning: ['basicCleaning', 'additionalServices'],
    upholsteryCleaning: ['cleaningFurniture'],
    carCleaning: ['carCleaningServices'], // Jeśli masz cennik dla czyszczenia aut
    // Dodaj pozostałe usługi
  };

  let pricingCategories = [];

  if (selectedService) {
    // Pobieramy kategorie cennika dla wybranej usługi
    pricingCategories = servicePricingMap[selectedService] || [];
  } else {
    // Zbieramy wszystkie kategorie cennika ze wszystkich usług
    const allCategories = new Set();
    Object.values(servicePricingMap).forEach((categories) => {
      categories.forEach((category) => allCategories.add(category));
    });
    pricingCategories = Array.from(allCategories);
  }

  // Generowanie tytułu
  const heading = selectedService
    ? `${t.pricingOfServicesFor} ${t.services[selectedService].title}`
    : t.pricingOfServices;

  return (
    <div>
      {/* Warunkowe wyświetlanie sekcji płatności */}
      {!selectedService && (
        <section className="payment-section">
          <h3 className="payment-title">
            <FontAwesomeIcon icon={faMoneyBillWave} className="icon" />
            {t.paymentSectionTitle}
          </h3>
          <p className="payment-description">{t.paymentDescription}</p>
        </section>
      )}

      <div className="pricing-container">
        <h2 className="pricing-title">{heading}</h2>

        {/* Renderujemy kategorie cennika */}
        {pricingCategories.map((categoryKey) => {
          const categoryTitle = t[categoryKey];
          const categoryDetails = t[`${categoryKey}Details`];

          if (!categoryDetails) {
            return null; // Pomijamy, jeśli nie ma szczegółów
          }

          return (
            <div className="pricing-category" key={categoryKey}>
              <button
                className="pricing-header"
                onClick={() => handleCategorySelect(categoryKey)}
                onKeyDown={(event) => handleKeyDown(event, categoryKey)}
                aria-expanded={selectedCategory === categoryKey}
              >
                {categoryTitle} {selectedCategory === categoryKey ? '-' : '+'}
              </button>
              {selectedCategory === categoryKey && (
                <div className="pricing-details expand">
                  {/* Renderowanie szczegółów kategorii zgodnie ze strukturą danych */}
                  {/* Sprawdźmy, czy istnieje pole 'price' i je wyświetlamy */}
                  {categoryDetails.price && (
                    <p className="pricing-overview">{categoryDetails.price}</p>
                  )}

                  {/* Jeśli są dostępne 'sections', renderujemy je */}
                  {categoryDetails.sections && (
                    <ul>
                      {Object.keys(categoryDetails.sections).map((sectionKey) => (
                        <li key={sectionKey} className="pricing-item">
                          <strong>
                            {categoryDetails.sections[sectionKey].title} -{' '}
                            {categoryDetails.sections[sectionKey].price}
                          </strong>
                          <ul className="sub-list">
                            <li>{categoryDetails.sections[sectionKey].details}</li>
                          </ul>
                        </li>
                      ))}
                    </ul>
                  )}

                  {/* Jeśli są podkategorie z 'items', renderujemy je */}
                  {Object.keys(categoryDetails).map((subCategoryKey) => {
                    const subCategory = categoryDetails[subCategoryKey];
                    if (
                      typeof subCategory === 'object' &&
                      subCategory.title &&
                      subCategory.items
                    ) {
                      return (
                        <div key={subCategoryKey} className="pricing-subcategory">
                          <h4>{subCategory.title}</h4>
                          <ul>
                            {subCategory.items.map((item, index) => (
                              <li key={index}>
                                {item.service} - {item.price}
                              </li>
                            ))}
                          </ul>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Pricing;

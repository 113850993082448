// UpholsteryCleaning.js
import React from 'react';
import { useAppContext } from '../../contexts/context';
import Pricing from './Pricing';
import Gallery from '../ui/Gallery';
import '../../styles/UpholsteryCleaning.css'; // Upewnij się, że plik istnieje
import upholsteryCleaningImage from '../../assets/upholstery-cleaning.jpg';

function UpholsteryCleaning() {
  const { t } = useAppContext();
  const service = t.services?.upholsteryCleaning;

  if (!service) {
    return <p>Dane nie są dostępne</p>;
  }

  return (
    <div>
      {/* Sekcja z opisem i obrazem obok siebie */}
      <div className="upholstery-cleaning-container">
        <div className="upholstery-cleaning-content">
          <h2>{service.title}</h2>
          <p dangerouslySetInnerHTML={{ __html: service.description }}></p>
        </div>
        <div className="upholstery-cleaning-image">
          <img src={upholsteryCleaningImage} alt={service.title} />
        </div>
      </div>

      {/* Kontener dla cennika i galerii obok siebie */}
      <div className="pricing-and-gallery">
      <div className="gallery-section">
          {/* Przekazujemy `carouselMode={true}` */}
          <Gallery selectedService="upholsteryCleaning" carouselMode={true} />
        </div>
        <div className="pricing-section">
          <Pricing selectedService="upholsteryCleaning" />
        </div>
      </div>
    </div>
  );
}

export default UpholsteryCleaning;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppProvider } from './contexts/context';
import Header from './components/layout/Header'; // Import Header component
import Footer from './components/shared/Footer'; // Import Footer component
import Gallery from './components/ui/Gallery';
import Home from './components/pages/Home';
import Pricing from './components/pages/Pricing';
import Contact from './components/pages/Contact';
import UpholsteryCleaning from './components/pages/UpholsteryCleaning';
import HomeCleaning from './components/pages/HomeCleaning';
import CarCleaning from './components/pages/CarCleaning';
import DrivewayCleaning from './components/pages/DrivewayCleaning';
import './styles/App.css'; // Import global styles

function MainLayout() {
  return (
    <>
      {/* Header */}
      <Header />

      {/* Main Content */}
      <main className="App-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/upholstery-cleaning" element={<UpholsteryCleaning />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/home-cleaning" element={<HomeCleaning />} />
          <Route path="/car-cleaning" element={<CarCleaning />} />
          <Route path="/driveway-cleaning" element={<DrivewayCleaning />} />
        </Routes>
      </main>

      {/* Footer */}
      <Footer />
    </>
  );
}

function App() {
  return (
    <AppProvider>
      <Router>
        <MainLayout />
      </Router>
    </AppProvider>
  );
}

export default App;
